import { appendUrlParams } from "utils/urlUtils";
import {
  TEMPLATE_UUID_PARAM,
  SALES_PAGE_UUID_PARAM,
} from "ui/pages/SalesPages/constants";
import { sales_pages_apis } from "ui/pages/SalesPages/API";
import { default_sales_page_stage_id } from "ui/pages/SalesPages/pages/SalesPagesHome/constants";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { OFFERING_STATUSES } from "constants/schedule";

export const SALES_PAGES_ROUTE_KEYS = {
  sales_pages_home: "sales_pages_home",
  sales_pages_create: "sales_pages_create",
  sales_pages_update_template: "sales_pages_update_template",
  sales_pages_update_details: "sales_pages_update_details",
  sales_pages_preview: "sales_pages_preview",
  sales_page_template_preview: "sales_page_template_preview",
};

export const SALES_PAGES_ROUTE_IDS = {
  [SALES_PAGES_ROUTE_KEYS.sales_pages_home]: "sales-pages",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_create]: "sales-pages/create",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_template]:
    "sales-pages/udpate/:sale_page_uuid/template",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_details]:
    "/sales-pages/update/details",
  [SALES_PAGES_ROUTE_KEYS.sales_pages_preview]:
    "sales-pages/preview/:sale_page_uuid",
  [SALES_PAGES_ROUTE_KEYS.sales_page_template_preview]:
    "sales-pages/template/preview/:templateUuid",
};

export const SALES_PAGES_ROUTE_GENERATORS = {
  [SALES_PAGES_ROUTE_KEYS.sales_pages_home]: ({ utmParams = {} }) => {
    const basePath = `/${
      SALES_PAGES_ROUTE_IDS[SALES_PAGES_ROUTE_KEYS.sales_pages_home]
    }`;
    // Add UTM parameters if provided
    const utmString = new URLSearchParams(utmParams).toString();
    return utmString ? `${basePath}?${utmString}` : basePath;
  },
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_template]: ({ salePageUuid }) =>
    `sales-pages/udpate/${salePageUuid}/template`,
  [SALES_PAGES_ROUTE_KEYS.sales_pages_update_details]: ({
    salePageUuid,
    templateUuid,
  }) =>
    appendUrlParams(`/sales-pages/update/details`, {
      [TEMPLATE_UUID_PARAM]: templateUuid,
      [SALES_PAGE_UUID_PARAM]: salePageUuid,
    }),
  [SALES_PAGES_ROUTE_KEYS.sales_pages_preview]: ({ salePageUuid }) =>
    `sales-pages/preview/${salePageUuid}`,
  [SALES_PAGES_ROUTE_KEYS.sales_page_template_preview]: ({ templateUuid }) =>
    `/sales-pages/template/preview/${templateUuid}`,
};

export const SALES_PAGES_RESOURCE_APIS = {
  [SALES_PAGES_ROUTE_IDS[SALES_PAGES_ROUTE_KEYS.sales_pages_home]]: ({
    params,
  }) => {
    const queries = { ...params?.filter };
    queries[sales_page_home_stage_filter_key] =
      queries[sales_page_home_stage_filter_key] ?? default_sales_page_stage_id;
    if (queries.utm_source) delete queries.utm_source;
    return {
      api_end_point: sales_pages_apis.get_sales_pages_list,
      queries,
    };
  },
};

export const sales_page_detail_keys = {
  uuid: "uuid",
  title: "title",
  sales_page_stage: "sales_page_stage",
  listing_title: "listing_title",
  listing_uuid: "listing_uuid",
  listing_type: "listing_type", // lsiting type. Refer schedule_types_ids for this
  redirect_url: "redirect_url", // redirect url when register button is clicked.. leads to listing / checkout page
  sales_page_url: "sales_page_url",
  page_builder_source: "page_builder_source",
  template_uid: "template_uid",
  url_slug: "url_slug",
  metadata: "metadata", // object containing metadata. Refer `sales_page_metadata_keys` constant
  seo_details: "seo_details",
};
export const sales_page_metadata_keys = {
  cdn_url: "cdn_url",
  project_data_url: "project_data_url",
  cdn_draft_url: "cdn_draft_url",
  project_data_draft_url: "project_data_draft_url",
  page_builder_source: "page_builder_source", // we are keeping it in metadata as well as sales_page_detail_keys for "just in case" scenarios
  html_redirect_url: "html_redirect_url", // redirect url value used when sales page was last updated
};

export const sales_page_home_stage_filter_key =
  sales_page_detail_keys.sales_page_stage;

/**
 * refer TemplateDataInterface
 */
export const TEMPLATE_DETAIL_KEYS = {
  uuid: "uuid",
  slug: "slug",
  page_builder_source: "page_builder_source",
};

/**
 * depicts what builder was used for creating the page
 */
export const PAGE_BUILDER_SOURCES = {
  exly: 1,
  grapes_js: 2,
};

/**
 * Sale Page Section Slugs
 * Todo: More to be added here
 */
export const SALE_PAGE_SECTION_SLUGS = {
  content_catalogue: "ContentCatalogue",
};

export const sales_builder_types = {
  classic: 1,
  advanced: 2,
};
export const UTM_SRC_URL_PARAMS_KEY = "utm_source";
export const sales_builder_options = [
  {
    heading: "Classic Editor",
    description: "Easy setup—ideal for creating pages with minimal tweaks",
    value: sales_builder_types.classic,
    url: getS3AssetCDNUrl("assets/images/sales_pages/classic.png"),
  },
  {
    heading: "Advanced Builder",
    description:
      "Built for experts: Full design freedom with unmatched customization",
    value: sales_builder_types.advanced,
    url: getS3AssetCDNUrl("assets/images/sales_pages/advanced.png"),
  },
];

export const sales_page_template_tabs = {
  all: "all",
  popular: "popular",
};

export const sales_page_template_tabs_options = [
  {
    label: "All templates",
    value: sales_page_template_tabs.all,
  },
  {
    label: "Most popular",
    value: sales_page_template_tabs.popular,
  },
];

export const template_loading = getS3AssetCDNUrl(
  "assets/images/sales_pages/sales_template.png"
);

export const sales_page_info_icon = getS3AssetCDNUrl(
  "assets/svgs/sales_pages/lightbulb.svg"
);

export const sales_page_listing_status = [
  OFFERING_STATUSES.live.value,
  OFFERING_STATUSES.soldout.value,
];
