import ExlyTable from "common/Components/ExlyTable";
import { Header } from "common/Components/Header";
import React from "react";
import { EnableVariation } from "./Components/EnableVariation";
import columnStyle from "./Components/Columns/Columns.module.css";
import ExlyLoader from "ui/modules/ExlyLoader";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { AddVariation } from "./Components/AddVariation";
import {
  CREATE_VARIATION_CTA_TEXT,
  empty_state_props,
} from "features/OfferingVariations/OfferingVariations.constants";
import { getTableConfig } from "./OfferingVariationsList.tableConfig";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { Button } from "@my-scoot/component-library-legacy";
import { OfferingVariationContext } from "../OfferingVariationsWrapper";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { OFFERING_VARIATIONS_LEARN_MORE_HREFS } from "./OfferingVariationsList.constants";

export const OfferingVariationsList = withComponentLibraryTheme((props) => {
  const isDesktop = useIsDesktop();

  useAppLayoutControl({ values: { showBottomNav: false } });
  const {
    deleteVariation,
    showDeleteModal,
    processing,
    handleEditVariation,
    closeModal,
    setSelectedVariation,
    setShowDeleteModal,
    isEnabled,
    footerProps,
    setShowAddVariation,
  } = React.useContext(OfferingVariationContext);

  return (
    <>
      {isDesktop && (
        <Header
          hideBackButton
          title={
            isDesktop ? `Create and Manage Variations` : "Manage Variations"
          }
          classNames={{
            container: `!tw-pt-0 !tw-pb-8`,
            title: "!tw-text-[24px] !tw-font-bold",
          }}
          right={
            isDesktop ? (
              <div className="d-flex">
                <LearnMoreCta
                  href={
                    OFFERING_VARIATIONS_LEARN_MORE_HREFS.OFFERING_VARTIATION
                  }
                />
                <Button
                  disabled={!isEnabled}
                  onClick={() => setShowAddVariation(true)}
                  className="ml-3"
                >
                  {CREATE_VARIATION_CTA_TEXT}
                </Button>
              </div>
            ) : (
              <LearnMoreCta
                href={OFFERING_VARIATIONS_LEARN_MORE_HREFS.OFFERING_VARTIATION}
              />
            )
          }
        />
      )}
      {processing ? (
        <ExlyLoader />
      ) : (
        <>
          <EnableVariation />
          <div className={isDesktop ? `tw-pt-3 tw-pb-3` : "p-3"}>
            <ExlyTable
              ra_props={props}
              columnConfig={getTableConfig()}
              primaryKey="id"
              customPrimaryAction={{
                label: "Edit Variation",
                onClick: (row) => handleEditVariation(row),
              }}
              hasMobileFooter
              noExportButton={true}
              footerProps={footerProps}
              renderSecondaryAction={(record) => (
                <DeleteIcon
                  onClick={() => {
                    if (!isEnabled) return;
                    setSelectedVariation(record);
                    setShowDeleteModal(true);
                  }}
                  className={`${columnStyle.deleteIcon}`}
                />
              )}
              borderedFields={true}
              fieldsAlignment="space-between"
              recordFooterVariant="secondary"
              layoutProps={{
                exlyTableRootClass: !isEnabled ? "opacity-50" : "",
                showFixedBars: true,
                noShadow: true,
                paddingBottom: "32px",
                paddingMobile: "10px 0",
                noMobileBoxShadow: true,
              }}
              {...empty_state_props}
            />
          </div>
          <ExlyDeleteModal
            onClose={closeModal}
            onDelete={deleteVariation}
            open={showDeleteModal}
            title="Confirm Delete"
            description="Are you sure you want to delete the selected offering variation?"
          />

          <AddVariation />
        </>
      )}
    </>
  );
});
